import React, { useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/esm/Container';
import AuthContext from '../context/AuthContext';
import history from '../history';
import { withRouter, generatePath, useLocation } from "react-router-dom";
import axios from 'axios'

import Button from 'react-bootstrap/esm/Button';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/esm/Image';

import * as createjs from 'createjs-module';


const InteractionsPage = () => {
    let { isAuthenticated, authTokens, user, url } = useContext(AuthContext)

    const [appState, setAppState] = useState({
        isLoading: true,
        interactions: [],
        users: [],
    })

    const location = useLocation()

    let patient_id = location.state.patient_id
    let date = location.state.photo.date_created
    let image_id = location.state.photo.id

    let getInteractions = async () => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/image/' + image_id + '/interactions', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            }
        })
        let data = await response.json()
        if (response.status === 200) {
            setAppState({
                isLoading: false,
                interactions: data['interactions'],
                users: data['users']
            })
        }
    }

    useEffect(() => {
        getInteractions()
    }, [])


    let getInteractionType = (num) => {
        if(num === 1) {
            return "Predict"
        }
        else if (num === 2){
            return "Plot"
        }
        else if (num === 3){
            return "Save Keypoints"
        }
        else if (num === 4){
            return "Save features and classify"
        }
        else if (num === 5){
            return "Subjective Classification"
        }
    }

    return (
        <Container>

            <h1 className="mb-5 mt-3">Interactions</h1>

            <Row xs={1} md={3} className='my-3 justify-content-center'>
                {
                    appState.interactions.map(interaction => {
                        let username = null
                        let path = null
                        appState.users.map(user => {
                            if (user.user === interaction.author) {
                                username = user.username
                                path = user.image
                            }
                        })
                        let date = new Date(interaction.date_created)
                        return (
                            <Card key={interaction.id} className="mx-3 my-2 patientOption-button" style={{ width: '17vw', height: 'fit-content', }}>
                                <Card.Body className='text-center'>
                                    <Card.Title as="h3" className='mb-4'>
                                        {username}
                                    </Card.Title>
                                    <Card.Img variant="top" src={'https://breloai-backend.inesctec.pt' + path} height="150" />
                                    <Card.Text className='mt-2'>
                                        Creation Date: {date.toLocaleDateString()}-{date.toLocaleTimeString()}
                                    </Card.Text>
                                    <Card.Text>
                                        Interaction Type: {getInteractionType(interaction.interaction_type)}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        )
                    })
                }
            </Row>

        </Container>
    )

}

export default withRouter(InteractionsPage)